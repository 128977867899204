import React from "react";
import { Col } from "react-bootstrap";

function ExperiencePage(){
    return(
        <Col lg="8" id="portfolio-content" className="parent-container">
            <div className="content-container">
                <div className="item-header" >
                    <span className="item-header-left-item">Software Developer at Harmonic Fund Services</span>
                    <span className="item-header-right-item">June 2017 - Present</span>
                </div>
                <ul>
                    <li>
                        Work with local and remote teams in Russia and Cayman, where most technologies are center around .NET framework, CSLA Architecture, SQL Server, HTML, CSS and Javascript.
                    </li>
                    <li>
                        Performed large refactoring of legacy codes to reduce the computing time by 50% for an important report used to manage position in client's portfolio. 
                    </li>
                    <li>
                        Responsible for maintaining and adding new features to a drawing-based web application that shows the transactions between entities and instruments. The frontend uses Jquery, Jsplumb and Panzoom. The transactions are then pushed to the data warehouse using C# and SQL Server.
                    </li>
                    <li>
                        Worked on a loan managing project which is responsible for converting PDF files to HTML using Aspose and use a machine learning API which automatically links relevant phrases to relevant fields in a form. 
                    </li>
                    <li>
                        Designed, implemented and maintain report generation for financial web application that manages portfolio for clients.
                    </li>
                </ul>
            </div>
            <div className="content-container">
                <div className="item-header">
                    <span className="item-header-left-item">Software Developer at GIMME360</span>
                    <span className="item-header-right-item">Feb 2017 - May 2017</span>
                </div>
                <ul>
                    <li>
                        Developed an Augmented Reality product using Unity, Vuforia and C#. The Augmented Reality apps were compiled into Android apps.
                    </li>
                    <li>
                        Frontend and Backend implementation for their product, including user authentication through Firebase and UI design.
                    </li>
                    <li>
                        Developed a multiplayer online Augmented Reality Air hockey game using Photon Unity Network.
                    </li>
                </ul>
            </div>
            <div className="content-container">
                <div className="item-header">
                    <span className="item-header-left-item">Software Test Engineer Intern  at ELL Technologies</span>
                    <span className="item-header-right-item">Oct 2016 - Nov 2016</span>
                </div>
                <ul>
                    <li>
                        Set up the test framework for a component of a learning management system (LMS) using Spock, Geb and Selenium.
                    </li>
                    <li>
                        Wrote various test cases to test an auto-login component using the developed test framework
                    </li>
                    <li>
                        Performed manual regression testing on bug fixes and new feature added to ensure software is still working properly.
                    </li>
                    <li>
                        Wrote a utility class to generate entries in the database via Grails' domain classes so that automated tests can use it to create new random users and clients.
                    </li>
                </ul>
            </div>
        </Col>
    )
}

export default ExperiencePage;
