import React from  "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function LeftMenu(){
    return(
        <Col lg="2" id="left-menu-container">
            <ul className="left-menu-list">
                <li className="left-menu-item">
                    <Link to="/home">
                        <div className="left-menu-item">HOME</div>
                    </Link>
                </li>
                <li className="left-menu-item">
                    <Link to="/experience">
                        <div className="left-menu-item">EXPERIENCE</div>
                    </Link>
                </li>
                <li className="left-menu-item">
                    <Link to="/projects">
                        <div className="left-menu-item">PROJECTS</div>
                    </Link>
                    <ul className="left-menu-list">
                        <li>
                            <Link to="/projects/killallaliens">
                                <div className="left-menu-item">Kill All Aliens</div>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </Col>
    )
}

export default LeftMenu;