import React from "react";
import { Col } from "react-bootstrap";
import LinkedInLogo from "../Images/LI_Logo.png";
import GitHubLogo from "../Images/GitHub_Logo.png";

function RightMenu(){
    return(
        <Col lg="2" id="right-menu-container">
            <span className="right-panel-item">
                <a href="https://www.linkedin.com/in/yao-wen-kok-102976121">
                    <img src={LinkedInLogo} />
                </a>
            </span>
            <span className="right-panel-item">
                <a href="https://github.com/YaoWen728">
                    <img src={GitHubLogo} />
                </a>
            </span>
        </Col>
    )
}

export default RightMenu;