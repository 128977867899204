import React from "react";
import { Col } from "react-bootstrap";

function HomePage(props){
    return(
        <Col lg="8" id="home-content" className="parent-container">
            <div id="home-intro" className="content-container">
                    Welcome to my page! <br />
                    <br />
                    I am Yao-Wen Kok, a Software Developers currently working with a Financial Company in Toronto. <br />
                    <br />
                    I specialized in web technologies such as MVC .Net, CSS, HTML and Javascript. <br />
                    <br />
                    Self Improvment, learning new technologies and making great software is a passion of mine. <br />
                    <br />
                    This page is build with React, Cloudfront, S3 and Route 53. <br />
                    <br />
                    For any business inquiry, feel free to contact me at rp559896@gmail.com <br />
            </div>
        </Col>
    )
}

export default HomePage;