import React from "react";

function KillAllAliens(){
    return(
    <div className="content-container">
        <div className="item-header" >
            <span className="item-header-left-item">Kill All Aliens</span>
        </div>
        What happens when Aliens decides to f*** with mother earth? YOU KILL EM ALL! <br />
        <br />
        Controls: <br />
        Q - A toggle which switch the trajectory of the projectiles <br />
        W - Creates a shield that deflects incoming projectile, but has a cooldown of 60 seconds <br />
        Space - Fires projectile, trajectory can be changed by hitting 'Q' <br />
        <br />
        The Boss has 900 hp. Shooting it on the sides will deal 1 damager per shot, but shooting it dead center will deal 2 damage! <br />
        <br />
        Defeat the boss and the game ends! <br />
        <br />
        Special thanks to Aaron Ong for providing the art, check out his linkedin here: <a href="https://ca.linkedin.com/in/maddsketch">https://ca.linkedin.com/in/maddsketch</a><br/>
        <br/>
        <iframe id="killallaliens" src="https://makegreatsoftware.work/killallaliens/index.html"></iframe> 
    </div>
    )
}

export default KillAllAliens;