import React from "react"

function Header(){
    return(
        <header id="header-container" className="jumbotron text-center">
            <div className="main-header site-header">YAO-WEN KOK</div>
            <div className="main-header-line header-line" style={{ width: "465px", height:"4px", marginBottom: "7px" }}></div>
            <div className="sub-header site-header">MAKE GREAT SOFTWARE</div>
            <div className="sub-header-line header-line" style={{ width: "410px", height:"2px" }}></div>
        </header>
    );
}

export default Header;