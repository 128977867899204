import "./App.css";
import LeftMenu from "./Components/LeftMenu";
import RightMenu from "./Components/RightMenu";
import Header from "./Components/Header";
import BodyContent from "./Components/BodyContent"
import "bootstrap/dist/css/bootstrap.min.css"
import  { Container, Row }  from "react-bootstrap";
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div>
      <Header />
        <Container fluid>
          <Row>
            <Router>
              <LeftMenu />
              <BodyContent />
              <RightMenu />
            </Router>
          </Row>
        </Container>
    </div>
  );
}

export default App;
