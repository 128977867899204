import React from "react";
import KillAllAliens from "./Projects/KillAllAliens"
import ProjectList from "./Projects/ProjectList"
import { Col } from "react-bootstrap";
import { Route, Switch } from 'react-router-dom';

function ProjectsPage(){
    return(
        <Col lg="8" id="projects-content">
            <Switch> 
                <Route path="/Projects" exact component={ProjectList} />
                <Route path="/Projects/KillAllAliens" exact component={KillAllAliens} />
            </Switch>
        </Col>
    )
}

export default ProjectsPage;