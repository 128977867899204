import React from "react";
import HomePage from "./HomePage";
import ProjectsPage from "./ProjectsPage";
import ExperiencePage from "./ExperiencePage";
import { Route, Switch } from 'react-router-dom';

function BodyContent(){
    return(
        <Switch> 
            {/* Switch stops it from going through all the route */}
            {/* <Route path="/" exact render={() => <HomePage text="You will never beat the fucking irish! " />} /> */}
            <Route path="/" exact component={HomePage} />
            <Route path="/Home" component={HomePage} />
            <Route path="/Projects" component={ProjectsPage}/>
            <Route path="/Experience" component={ExperiencePage}/>
        </Switch>

    )
}

export default BodyContent;