import React from "react";
import { Link } from "react-router-dom";

function ProjectList(){
    return(
        <div className="content-container">
                <div className="item-header" >
                    <span className="item-header-left-item">Kill All Aliens</span>
                </div>
                <div>
                    Kill All Aliens is a classic shoot em up game! Try it out <Link to="/Projects/KillAllAliens" >here</Link>!<br/>
                    <br/>
                    Bullet script, action script and movement script are all written by Yao-Wen Kok.<br/>
                    <br/>
                    Developed by: Yao-Wen Kok<br/>
                    Art provided by: <a href="https://ca.linkedin.com/in/maddsketch">Aaron Ong</a>
                </div>
        </div>
    )
}

export default ProjectList;